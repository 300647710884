@import "styles/_mixins.module";
@import "styles/_variables.module";
@import "styles/_reboot.module";
@import "styles/_colors.module";
@import "styles/_utils.module";

html,
body,
#terraswap {
  width: 100%;
  height: 100%;
  font-size: $font-size;
  position: relative;
  background-color: $realblue;
  background-image: url("assets/images/img-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  font-family: $font-family-swap;
  padding-bottom: $footer-height;
  background-attachment: fixed;

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: lightgray;
  }
  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  @include mobile {
    padding-bottom: $footer-height-mobile;

    .desktop-only {
      display: none !important;
    }
  }

  @include desktop {
    .mobile-only {
      display: none !important;
    }
  }
}

#basement {
  padding-bottom: $footer-height;

  @include mobile {
    padding-bottom: $footer-height-mobile;
  }
}

$loading_offset: 187;
$loading_duration: 1.4s;

.loading-spinner {
  animation: rotator $loading_duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.loading-path {
  stroke-dasharray: $loading_offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $loading_duration ease-in-out infinite;
  stroke: #ffffff;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $loading_offset;
  }
  50% {
    stroke-dashoffset: $loading_offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $loading_offset;
    transform: rotate(450deg);
  }
}
